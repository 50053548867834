<template>
  <div class="animated fadeIn">
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>
        <template slot="header">
          <span class="mt-2">Vet Onboarding</span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
        </template>

        <b-card no-body id="data">

          <b-tabs card>

            <b-tab title="General" :class="{ 'active': index === 0 }" class="mt-2">

              <table class="table" v-if="user">
                  <tbody>
                      <tr v-for="(v, k, index) in user" :key="index" v-if="k !== 'id' && k !== 'surgeons' && k !== 'documents' && k !== 'vet_practice' && k !== 'name'">
                          <th>{{ k|capitalize }}</th>
                          <td v-if="k === 'groups'">{{ userGroups }}</td>
                          <td v-else-if="k === 'practice_name'">
                            <router-link :to="{name: 'Vet', params: {id: user.vet_practice}}">{{ v }}</router-link>
                          </td>
                          <td v-else>{{ v }}</td>
                      </tr>
                  </tbody>
              </table>

              <b-row class="mt-10" v-if="user.status === 'Pending'">
                <b-col>
                  <b-button variant="danger" @click="declineOnboarding">Decline</b-button>
                </b-col>
                <b-col>
                  <b-button variant="success" class="float-right" @click="approveOnboarding">Approve</b-button>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab title="Documents" class="mt-2">
              <tbody>
                <tr v-for="(doc, index) in user.documents" :key="'docs_'+index">
                  <!--<th>{{ k|capitalize }}</th>-->
                  <th class="align-top pt-2">{{ doc.name }}</th>
                  <td class="align-top pb-5">
                    <b-button 
                      v-for="(link, link_index) in doc.files" 
                      :key="'link_'+link_index"
                      @click="openInNewTab(link);"
                    >View</b-button><br/>
                  </td>
                </tr>
              </tbody>
            </b-tab>

            <!--<b-tab title="Surgeons" class="mt-2">

              <table class="table mb-3" v-for="(surgeon , index) in user.surgeons" :key="index" >
                  <tbody>
                      <tr class="bg-light"><td colspan="2"># {{index + 1}}</td></tr>
                      <tr v-for="(v, k, index_) in surgeon" :key="index_" v-if="k !== 'id' && k !== 'all_groups' ">
                          <th>{{ k|capitalize }}</th>
                          <td v-if="k === 'groups'">{{ userGroups }}</td>
                        <td v-else>{{ v }}</td>
                      </tr>
                  </tbody>
              </table>

            </b-tab>-->

            <!--<add-task v-bind:task_type="'vet'" v-bind:reference="this.$route.params.id"></add-task>-->

        </b-tabs>

        </b-card>

        <template slot="footer">
          <b-button @click="goBack" class="float-left">Back</b-button>
          <!--<b-button v-show="hasFullAccess" :to="{ name: 'UserEdit', params: { id: user.id } }" variant="outline-dark" class="float-right">Edit</b-button>
          <b-button v-show="hasFullAccess" :to="{ name: 'UserSetPassword', params: { id: user.id } }" variant="outline-dark" class="float-right mr-2">Set Password</b-button>-->
        </template>
      </b-card>
    </b-col>
  </b-row>

  </div>

</template>

<script>
  import axios from '../../../shared/axios/auth-no-loader'
  import Id from "bootstrap-vue/src/mixins/id";
  import correspondenceTab from '../utils/tabs/correspondence'

  export default {
    name: 'VetOnboarding',
    components: {Id,
      'correspondence-tab': correspondenceTab,
    },
    props: {
      caption: {
        type: String,
        default: 'Vet Onboarding'
      },
    },
    data: () => {
      return {
        user: null,
        pdf: null,
        pdf_link: null,
        get_document: false,
        index: 0
      }
    },
    methods: {
      openInNewTab(url){
        window.open(url, "_blank");
      },
      goBack() {
        this.$router.go(-1)
      },
      getUser() {
        axios.get('/users/vet/' + this.$route.params.id + '/onboarding/' + this.$route.params.onboarding_id + '/').then(
          response => {
            this.user = response.data
          }
        ).catch(error => {
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }
            console.log('premium error');
            console.log(error.response)
          })
        return this.user
      },
      declineOnboarding() {
        let data = {
          status: 3,
        }
        axios.patch('/users/vet/' + this.$route.params.id + '/onboarding/' + this.$route.params.onboarding_id + '/', data).then(
          response => {
            this.user = response.data
          }
        ).catch(error => {
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }
            console.log('premium error');
            console.log(error.response)
          })
      },
      approveOnboarding() {
        let data = {
          status: 2,
        }
        axios.patch('/users/vet/' + this.$route.params.id + '/onboarding/' + this.$route.params.onboarding_id + '/', data).then(
          response => {
            this.user = response.data
            alert("vet user created and email sent")
          }
        ).catch(error => {
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }
            console.log('premium error');
            console.log(error.response)
          })
      }
    },
    computed: {
      hasFullAccess() {

        let access = ['full_admin',];

        let allowed = false;

        access.forEach(item => {
          if (this.$store.getters.getUserPermissions.includes(item)) {
            allowed = true
          }
        })

        return allowed

      },
      userGroups() {
        let groups = ''

        if (this.user) {

          this.user.all_groups.filter(item => {

            let i = 0;

            this.user.groups.forEach(group => {

              if (item.value === group) {
                groups += item.text

                i += 1;

                if (this.user.groups.length > 1) {
                    groups += ', '
                }
              }
            })


          })
        }

        return groups

      },
    },
    mounted() {
      this.getUser()
    },
    watch: {
      $route(to, from) {
        // react to route changes...
        this.$router.replace(to.fullPath).catch(error => {
          console.log(error)
        }).then(
          this.getUser()
        );

      }
    }
  }
</script>

<style>

  #data .card-header {
    padding-top: 1.5rem;
  }
</style>
